import "../styles/Banner.css";
//Create Banner.css file and move banner specific files in there

function Banner() {
  return (
    <div className="banner">
      <div className="banner-content">
        <p className="banner-content-left">
          Your Richmond Area Water Damage Restoration Experts
        </p>
        <p className="banner-content-right">
          Free Estimates on Water Damage Restoration Service
        </p>
      </div>
    </div>
  );
}

export default Banner;
